import Gap from "../../../atoms/Gap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getServices } from "../../../../redux/action/service";

const SectionF = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getServices());
  }, [dispatch]);

  const { services } = useSelector((state) => state.services);

  return (
    <div style={styles.section}>
      <div className="container-fluid text-center">
        <Gap height={50} />
        <h1 style={styles.title}>
          HARGA SEWA{" "}
          <img
            src="assets/images/logo.png"
            className="img-fluid"
            style={styles.logo}
          />
        </h1>
        <Gap height={25} />
        <div style={styles.subTitle}>
          Harga sewa membuat penggunaan semakin fleksibel, dapat digunakan
          secara Optimal dengan dukungan dan pengembangan mengikuti pertumbuhan
          bisnis.
        </div>
        <Gap height={50} />
        <div className="row">
          {services?.map((value, index) => (
            <div className="col" key={index}>
              <div className="card mx-2">
                <div className="card-body">
                  <div style={styles.name}>Sewa / {value.name}</div>
                  <div style={styles.price}>
                    <s>{value.price}</s>
                  </div>
                  <div style={styles.discount}>
                    <i>{value.discount}</i>
                  </div>
                  <div style={styles.total}>{value.total}</div>
                  <Gap height={50} />
                  <div>{value.description}</div>
                  <Gap height={25} />
                  <div>
                    <button
                      className="btn btn-outline-warning btn-block"
                      style={styles.button}
                      onClick={() => {
                        navigate(`/cart?service=${value.id}`);
                      }}
                    >
                      Mulai Sewa
                    </button>
                  </div>
                  <div>
                    <button
                      className="btn btn-default btn-block"
                      style={styles.button}
                    >
                      Konsultasi
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <Gap height={50} />
      </div>
    </div>
  );
};

const styles = {
  section: {
    backgroundColor: "#f3f3f5",
  },
  title: {
    fontSize: "36px",
    fontWeight: "bold",
    color: "#203546",
  },
  logo: {
    maxWidth: 200,
  },
  subTitle: {
    fontSize: "20px",
    color: "#203546",
  },
  name: {
    fontSize: "22px",
    fontWeight: 500,
    color: "#203546",
  },
  price: {
    fontSize: "24px",
    color: "#203546",
  },
  discount: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#203546",
  },
  total: {
    fontSize: "26px",
    fontWeight: "bold",
    color: "#203546",
  },
  button: {
    height: "55px",
  },
};

export default SectionF;
