const stateBills = {
  bills: [],
};

const bills = (state = stateBills, action) => {
  if (action.type === "GET_BILLS") {
    return {
      ...state,
      bills: action.payload,
    };
  }

  return state;
};

export { bills };
