import axios from "axios";

const Api = axios.create({
  // baseURL: "http://202.138.231.181:5001/rfid_inventory_new_api/public/api/v1",
  // baseURL: "http://127.0.0.1:8000/api/v1",
  // baseURL: "http://192.168.1.41:8000/api/v1",
  baseURL: "https://apiwarehouse.rfidwarehouse.cloud/api/v1",
});

export default Api;
